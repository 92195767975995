import axios, { AxiosError } from 'axios'

import { envConfig } from '@utils/envConfig'

import type { ApiCallParams } from './index.types'

const retryLogin = (originalFunction, originalParams) => {
  // Handle current session key expiration.
  // Perform the OAuth refresh token flow to receive a new session token.
  envConfig.delete('BULLHORN_REST_TOKEN')
  return originalFunction(originalParams)
}

async function makeBhApiCall<T = any> ({
  headers,
  body,
  method
}: ApiCallParams<T>) {
  const apiKey = process.env.GATSBY_BULLHORN_API_GW_API_KEY
  const apiURL = process.env.GATSBY_BULLHORN_API_GW_AUTH_URL as string
  const getPresignedURL = process.env.GATSBY_API_GW_GET_PRESIGNED_URL as string

  try {
    // Handle file upload case based on entity
    if (method === 'parseToCandidate') {
      const fileName = (body as File).name
      const fileType = (body as File).type
      const presignedURL = await axios.get(
        `${getPresignedURL}?fileName=${fileName}&contentType=${fileType}`,
        {
          headers: {
            'x-api-key': apiKey
          }
        }
      )
      const { uploadUrl } = presignedURL.data

      // Use the pre-signed URL to upload the file to S3
      await axios.put(uploadUrl, body as File, {
        headers: {
          'Content-Type': fileType
        }
      })

      const formData = new FormData()
      formData.append('file', body as File)

      const response = await axios.post(apiURL, formData, {
        headers: {
          ...headers,
          'x-api-key': apiKey,
          'Content-Type': 'multipart/form-data'
        }
      })
      return { data: response.data, error: null }
    }

    const response = await axios.post(
      apiURL,
      {
        method,
        body
      },
      {
        headers: {
          'x-api-key': apiKey
        }
      }
    )

    return { data: response.data.data, error: null }
  } catch (error) {
    const UNAUTHORIZED_CODE = 401

    if ((error as AxiosError).response?.status === UNAUTHORIZED_CODE) {
      return retryLogin(makeBhApiCall, {
        method,
        body
      })
    }

    const errorMessage =
      (error as AxiosError).response?.data.errorMessage ??
      (error as AxiosError).message

    return {
      data: null,
      error: `Failed to perform an API request: ${errorMessage}`
    }
  }
}

export { makeBhApiCall }
