import React, { forwardRef } from 'react'
import { Link } from 'gatsby'

import {
  style_dialogMenu,
  style_dialogMenuItem,
  style_link
} from './AvatarMenu.module.css'

const AvatarMenu = ({ onClick }, ref) => (
  <ul ref={ref} className={style_dialogMenu}>
    <li className={style_dialogMenuItem}>
      <Link className={style_link} to="/faq/" onClick={onClick}>
        FAQ
      </Link>
    </li>
  </ul>
)

export default forwardRef(AvatarMenu)
