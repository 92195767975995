import React, { FunctionComponent } from 'react'

import { JobCardListContainer } from '@containers/JobCardListContainer'
import { TwoColumnLayout } from '@components/redesign/TwoColumnLayout'

import { IJobsPageLayoutProps } from './JobsPageLayout.types'

const JobsPageLayout: FunctionComponent<IJobsPageLayoutProps> = ({
  content
}) => <TwoColumnLayout sidebar={JobCardListContainer} content={content} />

export default JobsPageLayout
