import React, { FunctionComponent } from 'react'

import { HeaderContainer } from '@components/app/Header'
import { AppLayout } from '@components/redesign/AppLayout'

import { JobsProvider } from '@hooks/useJobsContext'
import { isSSR } from '@utils/helpers'

import { IAppLayoutContainerProps } from './AppLayoutContainer.types'

const AppLayoutContainer: FunctionComponent<IAppLayoutContainerProps> = ({
  children,
  shouldRenderOnSSR,
  PageLayout
}) => {
  const isBlockedOnServerSide = Boolean(isSSR && shouldRenderOnSSR === false)

  if (isBlockedOnServerSide) {
    return null
  }

  return (
    <JobsProvider>
      <AppLayout
        header={<HeaderContainer />}
        hideOverflow={Boolean(PageLayout)}
      >
        {PageLayout ? <PageLayout>{children}</PageLayout> : children}
      </AppLayout>
    </JobsProvider>
  )
}

export default AppLayoutContainer
