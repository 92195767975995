import WrapPageElement from './WrapPageElement'

const storiesConfig = {
  title: 'Container Components/WrapPageElement',
  component: WrapPageElement,
  argTypes: {
    path: {
      options: [
        '/',
        '/applicant/',
        '/profile/',
        '/profile/overview/',
        '/profile/cv-resume/',
        '/profile/job-preferences/',
        '/settings/',
        '/settings/email-notifications/',
        '/404/',
        '/error/',
        '/feedback/Feedback/',
        '/feedback/',
        '/jobs/[slug]/',
        '/jobs/',
        '/jobs/my-applications/',
        '/unsubscribe/',
        '/unsubscribe/job-alerts/',
        '/feedback/already-received/FeedbackAlreadyReceived/',
        '/feedback/already-received/',
        '/feedback/error/FeedbackError/',
        '/feedback/error/'
      ],
      control: 'select'
    }
  },
  parameters: {
    layout: 'fullscreen',
    controls: {
      exclude: ['children', 'element', 'props']
    }
  }
}

export { WrapPageElement, storiesConfig }
