import { SelectJobFromIdArgs } from './jobHelpers.types'

export const getSelectedJobId = (pathname: string) =>
  pathname?.split('/')[2]?.trim()?.toLowerCase()

export const selectJobFromId = ({
  jobs = [],
  selectedJobId = ''
}: SelectJobFromIdArgs) =>
  jobs.find(({ id }) => id.toString() === selectedJobId)
