type EnvConfigKeys = 'BULLHORN_REST_TOKEN' | 'BULLHORN_REFRESH_TOKEN'

type ConfigValues = Record<EnvConfigKeys, string | undefined>

class EnvConfigService {
  private values: ConfigValues = {
    BULLHORN_REFRESH_TOKEN: process.env.BULLHORN_REFRESH_TOKEN,
    BULLHORN_REST_TOKEN: process.env.BULLHORN_REST_TOKEN
  }

  set (key: EnvConfigKeys, value: string): void {
    this.values[key] = value
  }

  get (key: EnvConfigKeys): string | undefined {
    return this.values[key]
  }

  delete (key: EnvConfigKeys): void {
    this.values[key] = undefined
  }
}

export const envConfig = new EnvConfigService()
