// https://docs.sentry.io/platforms/javascript/guides/gatsby/

import * as Sentry from '@sentry/gatsby'

const SENTRY_DSN = process.env.SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT

const sentryDebug = process.env.SENTRY_DEBUG === 'true'

const sentryTraceSampleRate = process.env.SENTRY_TRACE_RATE
const sentryEventRate = process.env.SENTRY_EVENT_RATE

// If no DSN, don't bother configuring Sentry
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: sentryTraceSampleRate
      ? Number(sentryTraceSampleRate)
      : 1.0,
    sampleRate: sentryEventRate ? Number(sentryEventRate) : 1.0,

    environment: SENTRY_ENVIRONMENT,
    debug: sentryDebug,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}
