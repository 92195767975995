import React, { Suspense, useState } from 'react'

import { MobileUnauthenticatedHeaderMenu } from '@components/app/Header/Mobile/MobileUnauthenticatedHeaderMenu'
import { Svg, SVGS } from '@components/redesign/Svgs'

import {
  style_menuButton,
  popperOpenAnimation,
  popperCloseAnimation
} from './MobileHeaderMenuButton.module.css'

const Popper = React.lazy(() => import('@components/generic/Popper/Popper'))

const MobileHeaderMenuButton = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <Suspense fallback={<div />}>
      <Popper
        blockScroll={true}
        visibleBackdrop={true}
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        classes={{
          openAnimation: popperOpenAnimation,
          closeAnimation: popperCloseAnimation
        }}
        content={<MobileUnauthenticatedHeaderMenu />}
      >
        <div className={style_menuButton}>
          {isMenuOpen ? (
            <button>
              <Svg name={SVGS.Close} />
            </button>
          ) : (
            <button>
              <Svg name={SVGS.Menu} />
            </button>
          )}
        </div>
      </Popper>
    </Suspense>
  )
}

export default MobileHeaderMenuButton
