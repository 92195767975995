import React, {
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
  PropsWithChildren,
  useMemo
} from 'react'
import { useLocation } from '@reach/router'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { fetchBhJobs } from '@services/job'
import { getSelectedJobId, selectJobFromId } from '@utils/jobHelpers'

import JobsContext from './JobsContext'

import { IJob, IJobResponse } from '@type/job'

/*
 * Provider created to share JobsList value and filters
 * between JobSearch and JobCardList components.
 */
const JobsProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [filteredJobs, setFilteredJobs] = useState<Array<IJob>>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSearchApplied, setSearchApplied] = useState<boolean>(false)
  const [selectedJob, setSelectedJob] = useState<IJob | undefined>(undefined)

  const { data: jobListRes, isLoading } = useQuery({
    queryKey: ['jobsList'],
    queryFn: () => fetchBhJobs() as Promise<AxiosResponse<IJobResponse>>
  })

  const jobs = useMemo(() => jobListRes?.data?.data ?? [], [jobListRes])

  const location = useLocation()
  const selectedJobId = getSelectedJobId(location?.pathname)

  const selectedJobFromId = selectedJobId
    ? selectJobFromId({
      jobs,
      selectedJobId
    })
    : undefined

  useEffect(() => {
    setSelectedJob(selectedJobFromId)
  }, [selectedJobFromId])

  const filterJobs = useCallback(
    (searchText: string, isApplied: boolean) => {
      const result = jobs.filter(({ title, publicDescription }) => {
        const formattedSearchText = searchText?.toLowerCase()?.trim()
        return (
          title?.toLowerCase()?.includes(formattedSearchText) ||
          publicDescription?.toLowerCase()?.includes(formattedSearchText)
        )
      })

      setFilteredJobs(result)
      setSearchApplied(isApplied)
      setSearchValue(searchText)
    },
    [jobs]
  )

  const contextValue = {
    isLoading,
    jobs,
    filteredJobs,
    filterJobs,
    searchValue,
    setSearchValue,
    isSearchApplied,
    setSearchApplied,
    selectedJob
  }

  return (
    <JobsContext.Provider value={contextValue}>{children}</JobsContext.Provider>
  )
}

export default JobsProvider
