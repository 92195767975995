import React, { FunctionComponent } from 'react'

import {
  AppLayoutContainer,
  getRouteConfig
} from '@containers/AppLayoutContainer'
import { FaqPageLayout } from '@containers/FaqPageLayout'
import { JobsRoot } from '@containers/JobsRoot'

import { useCookieBanner } from '@hooks/useCookieBanner'

import { IWrapPageElementProps } from './WrapPageElement.types'

const WrapPageElement: FunctionComponent<IWrapPageElementProps> = ({
  path = '',
  element
}) => {
  const { renderCookieBanner } = useCookieBanner()

  const routesUsingAppLayout = {
    root: {
      route: '/',
      PageLayout: JobsRoot,
      isEnabled: true
    },
    jobs: {
      route: '/jobs',
      PageLayout: JobsRoot
    },
    faq: {
      route: '/faq',
      PageLayout: FaqPageLayout
    }
  }

  const routeConfig = getRouteConfig(routesUsingAppLayout, path)

  const isAppLayoutRequired =
    element && routeConfig && routeConfig.isEnabled !== false

  return (
    <>
      {renderCookieBanner()}
      {isAppLayoutRequired ? (
        <AppLayoutContainer {...routeConfig}>{element}</AppLayoutContainer>
      ) : (
        element || null
      )}
    </>
  )
}

export default WrapPageElement
