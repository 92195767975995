import React, { useEffect, useState } from 'react'

import { JobTag } from '@components/redesign/JobTag'

import { style_container } from './JobTags.module.css'
import {
  alpha_bitcoin_15,
  bitcoin,
  Neutral_8,
  boneChilling,
  calcareousSinter,
  creamAndButter,
  gloomyPurple,
  placeboPurple,
  veteransDayBlue,
  turquish,
  ardentCoral,
  roseFrost,
  earlyBlossom,
  fruitDove,
  husky,
  harborBlue,
  lighthouse
} from '@theme/colors.module.css'

export const JOBS_TAG_TYPES = {
  NEW: 'new',
  BEST_MATCH: 'bestMatch',
  URGENT: 'urgent',
  ONGOING: 'ongoing',
  IN_REVIEW: 'inReview',
  PENDING: 'pending',
  IN_DEMAND: 'inDemand',
  TRENDING: 'trending',
  ALWAYS_HIRING: 'alwaysHiring',
  CLOSED: 'closed'
}

const JOBS_TAGS_MAPPER = {
  [JOBS_TAG_TYPES.ONGOING]: {
    backgroundColor: calcareousSinter,
    color: veteransDayBlue,
    value: 'Ongoing'
  },
  [JOBS_TAG_TYPES.IN_REVIEW]: {
    backgroundColor: alpha_bitcoin_15,
    border: `1px solid ${bitcoin}`,
    color: bitcoin,
    value: 'In Review'
  },
  [JOBS_TAG_TYPES.IN_DEMAND]: {
    backgroundColor: earlyBlossom,
    color: fruitDove,
    value: 'In Demand'
  },
  [JOBS_TAG_TYPES.BEST_MATCH]: {
    backgroundColor: boneChilling,
    color: turquish,
    value: 'Best match'
  },
  [JOBS_TAG_TYPES.TRENDING]: {
    backgroundColor: husky,
    color: harborBlue,
    value: 'Trending'
  },
  [JOBS_TAG_TYPES.URGENT]: {
    backgroundColor: placeboPurple,
    color: gloomyPurple,
    value: 'Urgent'
  },
  [JOBS_TAG_TYPES.NEW]: {
    backgroundColor: roseFrost,
    color: ardentCoral,
    value: 'New'
  },
  [JOBS_TAG_TYPES.PENDING]: {
    backgroundColor: creamAndButter,
    color: Neutral_8,
    value: 'Pending'
  },
  [JOBS_TAG_TYPES.ALWAYS_HIRING]: {
    backgroundColor: calcareousSinter,
    color: veteransDayBlue,
    value: 'Always Hiring'
  },
  [JOBS_TAG_TYPES.CLOSED]: {
    backgroundColor: lighthouse,
    color: Neutral_8,
    value: 'Closed'
  }
}

const JobTags = ({ job }) => {
  const { type, dateAdded, customInt1, isOpen } = job || {}
  const [tags, setTags] = useState([])

  useEffect(() => {
    setTags([])
  }, [job?.id])

  useEffect(() => {
    if (tags.length) {
      return
    }

    const jobCreationDate = new Date(dateAdded)
    const isNewJob =
      new Date() < jobCreationDate.setDate(jobCreationDate.getDate() + 28)

    if (isNewJob && !customInt1) {
      setTags(tagsState => [...tagsState, JOBS_TAG_TYPES.NEW])
    }

    // Urgent Tag
    if (type === 1) {
      setTags(tagsState => [...tagsState, JOBS_TAG_TYPES.URGENT])
    }

    // Always Hiring tag
    if (customInt1) {
      setTags(tagsState => [...tagsState, JOBS_TAG_TYPES.ALWAYS_HIRING])
    }
  }, [dateAdded, type, customInt1, tags.length])

  if (!isOpen) {
    const jobTagProperties = JOBS_TAGS_MAPPER[JOBS_TAG_TYPES.CLOSED]
    return (
      <div className={style_container}>
        <JobTag key="closed" {...jobTagProperties}></JobTag>
      </div>
    )
  }

  return (
    <div className={style_container}>
      {tags?.map(type => {
        const jobTagProperties =
          JOBS_TAGS_MAPPER[type] || JOBS_TAGS_MAPPER[JOBS_TAG_TYPES.BLUE]
        return <JobTag key={type} {...jobTagProperties}></JobTag>
      })}
    </div>
  )
}

export default JobTags
