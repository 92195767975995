import React, { forwardRef } from 'react'
import { Link } from 'gatsby'

import {
  style_dialogMenu,
  style_dialogMenuItem,
  style_link
} from './MobileUnauthenticatedHeaderMenu.module.css'

const MobileUnauthenticatedHeaderMenu = (_, ref) => (
  <ul ref={ref} className={style_dialogMenu}>
    <li className={style_dialogMenuItem}>
      <Link className={style_link} to="/jobs/">
          Jobs
      </Link>
    </li>
    <li className={style_dialogMenuItem}>
      <a
        className={style_link}
        href="https://x-team.com/blog/"
        target="_blank"
        rel="noreferrer"
      >
          Blog
      </a>
    </li>
    <li className={style_dialogMenuItem}>
      <a
        className={style_link}
        href="https://x-team.com/blog/unleash/"
        target="_blank"
        rel="noreferrer"
      >
          Unleash+
      </a>
    </li>
    <li className={style_dialogMenuItem}>
      <Link className={style_link} to="/faq/">
          FAQ
      </Link>
    </li>
  </ul>
)

export default forwardRef(MobileUnauthenticatedHeaderMenu)
