import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
  createRef,
  useCallback
} from 'react'

import {
  ICardTagsProps,
  ITag,
  ITagConfig,
  ComponentRefType
} from './CardTags.types'

import {
  style_default,
  style_redesign,
  style_tags,
  style_tags_visible,
  style_tags_hidden,
  style_tags_expanded,
  style_tags_row,
  style_tag,
  style_tag_hidden,
  style_tag_visible,
  style_extra_tag_count,
  style_extra_tag_count_clickable,
  style_extra_tag_count_only_child
} from './CardTags.module.css'

const CardTags: FunctionComponent<ICardTagsProps> = ({
  tags = [],
  isCollapsible = true,
  isExpandable = false,
  shouldSort = true,
  redesign = false
}) => {
  const tagsRef = useRef() as ComponentRefType
  const moreTagsRef = useRef() as ComponentRefType
  const tagsRefs = tags.map(() => createRef() as ComponentRefType)
  const [showAllTags, setShowAllTags] = useState(false)
  const [tagsConfig, setTagsConfig] = useState([] as ITagConfig[])
  const [tagsVisibleWidth, setTagsVisibleWidth] = useState(0)

  const tagList = tags.map(tag =>
    typeof tag === 'string' ? { id: tag, name: tag } : tag
  )

  if (shouldSort) {
    tagList.sort((a: ITag, b: ITag) => b.name.length - a.name.length)
  }

  const hasFinishedCalculateTagsConfig = tagsConfig.length === tags.length
  const calcVisibleTagsWidth = useCallback(() => {
    const tagsRefOffsetWidth =
      tagsRef?.current?.offsetWidth && tags.length > 1
        ? tagsRef?.current?.offsetWidth
        : 0
    const moreTagsRefOffsetWidth =
      moreTagsRef?.current && tags.length > 1
        ? moreTagsRef?.current?.offsetWidth
        : 0
    let visibleWidth
    if (tagsRefOffsetWidth) {
      visibleWidth = moreTagsRefOffsetWidth
        ? tagsRefOffsetWidth - moreTagsRefOffsetWidth
        : tagsRefOffsetWidth
    }
    return visibleWidth
  }, [tags])

  useEffect(() => {
    function calcTagsWidth () {
      let accWidth = 0
      const tagsConfigArray = tagList.reduce((acc, tag, i) => {
        const currentTagRef = tagsRefs && tagsRefs[i]
        const currentTagRefOffsetWidth =
          (currentTagRef?.current?.offsetWidth || 0) + i
        accWidth = currentTagRef
          ? accWidth + currentTagRefOffsetWidth
          : accWidth
        return [
          ...acc,
          {
            tag,
            accWidth
          }
        ]
      }, [] as ITagConfig[])
      setTagsConfig(tagsConfigArray)
    }
    if (!hasFinishedCalculateTagsConfig) {
      calcTagsWidth()
    }
  }, [calcVisibleTagsWidth, hasFinishedCalculateTagsConfig, tagList, tagsRefs])

  useEffect(() => {
    if (hasFinishedCalculateTagsConfig) {
      setTagsVisibleWidth(calcVisibleTagsWidth())
    }
  }, [calcVisibleTagsWidth, hasFinishedCalculateTagsConfig])

  useEffect(() => {
    function handleOnResize () {
      setTagsVisibleWidth(calcVisibleTagsWidth())
    }
    window.addEventListener('resize', handleOnResize)
    return () => window.removeEventListener('resize', handleOnResize)
  }, [calcVisibleTagsWidth, tags, tagsVisibleWidth])

  const onPlusButtonClicked = () => {
    if (!isExpandable) {
      return
    }
    setShowAllTags(true)
    setTagsVisibleWidth(tagsRef?.current?.offsetWidth || 0)
  }

  const firstHiddenTagIndex = tagsConfig.findIndex(
    config => config.accWidth >= tagsVisibleWidth
  )
  const tagsClassName = hasFinishedCalculateTagsConfig
    ? [
      redesign ? style_redesign : style_default,
      style_tags,
      style_tags_visible,
      showAllTags ? style_tags_expanded : ''
    ].join(' ')
    : [style_tags, style_tags_hidden].join(' ')

  const renderCollapsedTags = () => (
    <div className={style_tags_row} data-testid="CardTags-tags">
      {tagList.map((tag, index) => {
        const tagClassNames =
          showAllTags ||
          index < firstHiddenTagIndex ||
          firstHiddenTagIndex === -1 ||
          !hasFinishedCalculateTagsConfig
            ? [style_tag, style_tag_visible].join(' ')
            : [style_tag, style_tag_hidden].join(' ')
        return (
          <div
            key={tag.id}
            className={tagClassNames}
            data-testid="CardTags-tag"
            ref={tagsRefs[index]}
          >
            {tag.name}
          </div>
        )
      })}
    </div>
  )

  const renderExpandedTags = () => {
    const rows: ITagConfig[][] = []
    tagsConfig.forEach((config, index) => {
      const i = Math.floor(config.accWidth / tagsVisibleWidth)
      if (!rows[i]) {
        rows.push([config])
      } else {
        rows[i] = [...rows[i], config]
      }
    })
    return rows.map((row, key) => (
      <div
        key={`row-${key}`}
        className={style_tags_row}
        data-testid="CardTags-tags"
      >
        {row.map(config => (
          <div
            key={config.tag.id}
            className={style_tag}
            data-testid="CardTags-tag"
          >
            {config.tag.name}
          </div>
        ))}
      </div>
    ))
  }

  return (
    <div className={tagsClassName} ref={tagsRef}>
      {!isCollapsible || (showAllTags && hasFinishedCalculateTagsConfig)
        ? renderExpandedTags()
        : renderCollapsedTags()}
      {!showAllTags && firstHiddenTagIndex > -1 && (
        <div
          className={[
            style_extra_tag_count,
            isExpandable ? style_extra_tag_count_clickable : '',
            firstHiddenTagIndex === 0 ? style_extra_tag_count_only_child : ''
          ].join(' ')}
          onClick={onPlusButtonClicked}
          ref={moreTagsRef}
        >
          +{tags.length - firstHiddenTagIndex}
        </div>
      )}
    </div>
  )
}

export default CardTags
