import React, { useEffect } from 'react'

import { useMediaQuery } from '@hooks/useMediaQuery'
import { isSSR } from '@utils/helpers'

import { DesktopHeader } from './Desktop/DesktopHeader'
import { MobileHeader } from './Mobile/MobileHeader'

import { breakpointSM } from '@theme/breakpoints.module.css'

const HeaderContainer = ({ hideMenu = false }) => {
  const isMobile = !useMediaQuery(`(min-width: ${breakpointSM})`)

  // This is used to support legacy typography theme used on Header components
  useEffect(() => {
    const cssProperties = [
      `--headingFamily: "Inter", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;`,
      `--textFamily: "Inter", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;`,
      `--extraFamily: "Inter", Montserrat, Arial, sans-serif;`
    ].join('')
    document.documentElement.setAttribute('style', cssProperties)
  })

  return (
    <>
      {isMobile && !isSSR ? (
        <MobileHeader hideMenu={hideMenu} />
      ) : (
        <DesktopHeader hideMenu={true} />
      )}
    </>
  )
}

export default HeaderContainer
