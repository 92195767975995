import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState
} from 'react'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { useJobsContext } from '@hooks/useJobsContext'
import { applyForJob } from '@services/jobApplication'

import JobApplicationsContext from './JobApplicationsContext'

import type { ApplyForJobArgs } from '../../services/jobApplication/jobApplication.types'
import {
  JobApplicationSubmitErrors,
  JobApplicationStatus,
  type JobApplicationApiResponse
} from '@type/jobApplication'

const JobApplicationsProvider: FunctionComponent<PropsWithChildren> = ({
  children
}) => {
  const { selectedJob } = useJobsContext()
  const [jobApplicationStatus, setJobApplicationStatus] = useState(
    JobApplicationStatus.INITIAL
  )

  const applyJobMutation = useMutation<
    JobApplicationApiResponse,
    AxiosError,
    ApplyForJobArgs
  >({
    mutationFn: values => applyForJob(values),
    onSuccess: data => {
      if (data.error === JobApplicationSubmitErrors.ALREADY_APPLIED) {
        setJobApplicationStatus(JobApplicationStatus.ALREADY_APPLIED)
        return
      }
      setJobApplicationStatus(JobApplicationStatus.SUCCESSFUL)
    },
    onError: error => {
      setJobApplicationStatus(JobApplicationStatus.FAILED)
      console.error(error)
    }
  })

  const applyJob = (values: ApplyForJobArgs, callbacks?: any) =>
    applyJobMutation.mutateAsync(values, callbacks)

  useEffect(() => {
    if (!selectedJob) {
      return
    }

    setJobApplicationStatus(JobApplicationStatus.INITIAL)
  }, [selectedJob])

  const contextValue = {
    applyJob,
    processing: applyJobMutation.isLoading,
    jobApplicationStatus
  }

  return (
    <JobApplicationsContext.Provider value={contextValue}>
      {children}
    </JobApplicationsContext.Provider>
  )
}

export default JobApplicationsProvider
