import React, { FunctionComponent } from 'react'

import { IAppLayoutProps } from './AppLayout.types'

import {
  style_component,
  style_header,
  style_main,
  style_hideOverflow
} from './AppLayout.module.css'

const AppLayout: FunctionComponent<IAppLayoutProps> = ({
  children,
  header,
  hideOverflow
}) => {
  const mainElementClassNames = [
    style_main,
    hideOverflow ? style_hideOverflow : ''
  ].join(' ')

  return (
    <div data-testid="AppLayout" className={style_component}>
      <div className={style_header}>{header}</div>
      <main className={mainElementClassNames}>{children}</main>
    </div>
  )
}

export default AppLayout
