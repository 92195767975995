import React, { Suspense } from 'react'

import { AvatarMenu } from '@components/app/Header/Desktop/AvatarMenu'

import {
  popperOpenAnimation,
  popperCloseAnimation,
  style_avatarContainer,
  style_hambugerMenu
} from './HeaderAvatar.module.css'

const Popper = React.lazy(() => import('@components/generic/Popper/Popper'))

const HeaderAvatar = () => (
  <Suspense fallback={<div />}>
    <Popper
      classes={{
        openAnimation: popperOpenAnimation,
        closeAnimation: popperCloseAnimation
      }}
      content={<AvatarMenu />}
    >
      <div data-testid="avatar" className={style_avatarContainer}>
        <div className={style_hambugerMenu}></div>
      </div>
    </Popper>
  </Suspense>
)

export default HeaderAvatar
