import { makeBhApiCall } from '@utils/bullhornHelpers'

export const fetchBhJobs = async () => {
  const config = {
    method: 'searchJobs'
  }

  return await makeBhApiCall(config)
}

export const fetchBhJobWithId = async ({ id }: { id: number }) => {
  const config = {
    method: 'searchJobWithId',
    id
  }
  return await makeBhApiCall(config)
}
