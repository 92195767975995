import React, { useMemo } from 'react'
import { navigate } from 'gatsby'

import {
  WorkWithTopBrands,
  GlassdoorMostLovedCompany,
  DidYouKnow
} from '@components/redesign/AdditionalPanels'
import { JobCardList } from '@components/redesign/JobCardList'
import { JobResultsPanel } from '@components/redesign/JobResultsPanel'
import { JobSearch } from '@components/redesign/JobSearch'
import { TwoColumnListLoader } from '@components/redesign/TwoColumnLayout/TwoColumnListLoader'

import { useJobsContext } from '@hooks/useJobsContext'
import { size } from '@utils/helpers'

const JobCardListContainer = () => {
  const { jobs, filteredJobs, searchValue, isSearchApplied, selectedJob } =
    useJobsContext()

  const handleCardClick = job => {
    navigate(`/jobs/${job?.id}/`)
  }

  const isJobsEmpty = size(jobs) === 0
  const jobListValue = useMemo(
    () => (filteredJobs?.length > 0 ? filteredJobs : jobs),
    [filteredJobs, jobs]
  )

  const panels = [
    <GlassdoorMostLovedCompany key="GlassdoorMostLovedCompany" />,
    <WorkWithTopBrands key="WorkWithTopBrands" />,
    <DidYouKnow key="DidYouKnow" />
  ]

  return (
    <>
      <JobSearch hideOnMobile />
      <JobResultsPanel
        searchValue={searchValue}
        isSearchApplied={isSearchApplied}
        resultsLength={size(filteredJobs)}
      />
      {isJobsEmpty ? (
        <TwoColumnListLoader />
      ) : (
        <JobCardList
          jobs={jobListValue}
          jobsNavigate={handleCardClick}
          selectedJob={selectedJob}
          panels={panels}
        />
      )}
    </>
  )
}

export default JobCardListContainer
