import React from 'react'

import { MobileHeaderMenuButton } from '@components/app/Header/Mobile/MobileHeaderMenuButton'
import { XTeamLogo } from '@components/generic/XTeamLogo'
import { JobSearch } from '@components/redesign/JobSearch'

import { style_mobileHeader, style_logo, style_search, style_menu } from './MobileHeader.module.css'

const MobileHeader = ({ hideMenu }) => (
  <header className={style_mobileHeader}>
    <div className={style_logo}>
      <XTeamLogo />
    </div>
    <div className={style_search}>
      <JobSearch />
    </div>
    <div className={style_menu}>
      {!hideMenu && <MobileHeaderMenuButton />}
    </div>
  </header>
)

export default MobileHeader
