import type { EntityTypes } from '@bullhorn/bullhorn-types'
import { IJob } from '@type/job'
import { IUser } from '@type/user'

export type JobApplicationApiResponse = {
  data: {
    changeType: 'INSERT' | 'UPDATE'
    changedEntityId: number
    changedEntityType: EntityTypes
    data: any
  } | null
  error: string | null
}

export interface IJobApplication {
  id: number
  job: IJob
  applied: boolean
  active: boolean
  created_at: Date | string
  updated_at: Date | string
  appliedAt: Date | string
  closedAt: Date | string | null
  stage?: 'Pending' | 'Successful' | 'Rejected' | 'Expired' | 'Withdrawn'
  reviewers?: IUser[]
}

export enum JobApplicationStatus {
  INITIAL = 'INITIAL',
  SUCCESSFUL = 'SUCCESSFUL',
  ALREADY_APPLIED = 'ALREADY_APPLIED',
  FAILED = 'FAILED'
}

export enum JobApplicationSubmitErrors {
  ALREADY_APPLIED = 'ALREADY_APPLIED'
}
